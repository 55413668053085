import React from "react"

import PageHeader from "../../components/layout/PageHeader"
import SupportPage from "../../components/layout/SupportPage"
import GoogleImage from "../../components/GoogleImage"
import PDFDownloadBox from "../../components/PDFDownloadBox"

const InstallationScPage = () => {
	return (
		<SupportPage page="installation" title="Self Contained Sign Installation Instructions">
			<PageHeader title="Self Contained Sign Installation Instructions" />

            <PDFDownloadBox
				title="Download Installation Instructions"
                pdfUrl="documents/SignMonkey-Self-Contained-Lit-Shape-Installation.pdf"
				imageUrl="SignTypes/Install-Guide-thumb.jpg"
            />

			<h3>Included with sign:</h3>

			<p>
			Paper installation Pattern, ½” seal tight conduit and ½” nipple to attach to your electric box.
			</p>

			<h3>Step 1</h3>

			<p>
				Unroll pattern and tape to wall where letters are to be installed.<br />
				Check to make sure the sign is level and centered in the area.
			</p>

			<p className="help-block">
				There must be access to the back of the wall to connect 110-volt AC Power.<br />
				Use a tape that will not damage the wall surface when it is removed.<br />
				For unpainted brick you can use duct tape but remove as soon as done.<br />
			</p>

			<br />

			<p>
				Do not level with the bottoms of the sign or edge of paper, use provided level lines only.
			</p>

			<div>
				<GoogleImage src="Documents/installation-sc-01.png" required lazy alt="Self Contained illuminated sign pattern leveling" className="full" />
			</div>

			<br />

			<h3>Step 2 <small>Drill Holes</small></h3>

			<div className="gridTextImage">
				<div>
					Mounting Holes <GoogleImage src="Documents/mounting-holes.png" required lazy alt="Mounting Holes" />￼ are on the outside of Letters and Logos.<br />
					Drill pilot holes through the center of the mark for appropriate fastener.

					<p className="help-block">
						For wood surfaces we suggest galvanized wood screws. They can be painted to match wall. Concrete, Brick or cinderblock surfaces we suggest Tapcon® Screws.
						Your hardware store may suggest another type that will work for your application.
					</p>

				</div>
				<GoogleImage src="Documents/installation-sc-02.png" required lazy alt="Drill holes for mounting sign" className="full" />
			</div>

			<br />

			<div className="gridImageText">
				<GoogleImage src="Documents/installation-sc-03.png" required lazy alt="Drill power hole for installing illuminated sign power" className="full" />
				<div>
					Power Holes <GoogleImage src="Documents/power-holes.png" required lazy alt="Power Holes" />￼ are in the middle of the Letters &amp; Logos.<br />
					Drill a 3/4” hole completely through the wall to the inside of the building.
				</div>
			</div>

			<br />

			<h3>Step 3 <small>Installing Sign</small></h3>

			<p>
				Remove the pattern from the wall.
			</p>
			<p className="help-block">
				It is best to remove tape as soon as possible.
			</p>
			<p>
				Push AC wires into flexible conduit include with sign. Attach the conduit to the nipple on the back of
				the sign. While putting sign on the wall,shove the conduit with AC power wire through the wall so it
				can be reached from inside the building.
			</p>

			<GoogleImage src="Documents/installation-sc-04.png" required lazy alt="Install lit sign electrical wire" className="full" />

			<br />

			<p>
				Install on the wall using screws appropriate for the wall.
			</p>
			<p className="help-block">
				Screw is to be put through hole in the mounting tab and into pilot hole from pattern.
			</p>

			<GoogleImage src="Documents/installation-sc-05.png" required lazy alt="Mount self contained electric sign to wall" className="full" />

			<p>
				Inside the building, connect AC wires to your 110-volt electrical box as you would any light fixture.
			</p>

			<div className="warning glasses">
				<GoogleImage src="Documents/safteyglasses.png" required lazy alt="Safety Glasses" width="40" />
				Wear Safety Glasses when drilling holes
			</div>
		</SupportPage>
	)
}

export default InstallationScPage